/*
Template:  Webster - Responsive Multi-purpose HTML5 Template
Author: potenzaglobalsolutions.com
Design and Developed by: potenzaglobalsolutions.com

NOTE: This file contains blockquote CSS.
*/

/*************************
    blockquote
*************************/

.blockquote { font-size: 14px; padding: 10px 20px; margin: 0 0 20px; font-size: 17px; border-left: 5px solid #eee; position: relative; }
.blockquote cite { display: block; font-weight: 600; margin-top: 10px; }

/*blockquote-right*/
.blockquote-right { text-align: right; border-right: 5px solid #eee; border-left: 0; padding: 0 20px 0; }

/*blockquote-gray*/
.blockquote.gray-bg {border-left: 5px solid #6a1111; }
.blockquote-right.gray-bg { text-align: right; border-right: 5px solid #6a1111; border-left: 0; padding: 0 20px 0; }

/*quote*/
.quote { padding-top: 50px; }
.quote:before { content: "\201C"; font-family: Georgia, serif; font-size: 100px; font-weight: bold; color: #eee; position: absolute; left: 14px;top: -30px; }

/*quote right*/
.blockquote-right.quote:before { left: inherit; right: 14px; }

/*theme-bg*/
.blockquote.theme-bg { border: 0px; color: #ffffff; padding: 70px 30px 30px; border-radius: 6px; }
.blockquote.theme-bg:before { left: 24px; top: -10px; }

/*theme-bg*/
.blockquote.black-bg { border: 0px; color: #ffffff; padding: 70px 30px 30px; border-radius: 6px; }
.blockquote.black-bg:before { left: 24px; top: -10px; left: inherit; }