/*
Template:  Webster - Responsive Multi-purpose HTML5 Template
Author: potenzaglobalsolutions.com
Design and Developed by: potenzaglobalsolutions.com

NOTE: This file contains Counter CSS.
*/ 

/*************************
        Counter
*************************/
.counter{ position:relative; color:#353535; min-height:60px; }
.counter .icon { font-size: 40px; }
.counter .timer { display: block; font-size: 44px; font-weight: 600; line-height: 40px; margin: 20px 0 5px; }
.counter label { font-size: 15px; font-weight: 600; margin: 10px 0 0; position: relative; text-transform: capitalize; }
.counter.text-white { color:#ffffff; }
.counter.text-white label { color:#ffffff; }
.counter.text-white span { color:#ffffff; }
.counter.theme-color { color:#6a1111; }
.counter.theme-color label { color:#6a1111; }
.counter.theme-color span { color:#6a1111; }


/*counter-small*/
.counter.counter-small .timer { font-size: 30px; font-weight: 600; }
.counter.counter-small label { font-size: 16px; font-weight: 400; margin-top: 20px; }
.counter.counter-small .icon {  font-size: 30px; line-height: 40px; }

/*big-counter*/
.counter.big-counter .timer { font-size: 70px; font-weight: 600; }
.counter.big-counter label { font-size: 18px; font-weight: 300; margin-top: 20px; }
.counter.big-counter icon {  font-size: 50px; line-height: 60px; }

 /*left-icon*/
.counter.left-icon { position:relative; padding-left:70px; }
.counter.left-icon .icon { font-size:40px; line-height:50px; position:absolute; left:0; bottom:auto; top:0; }
.counter.left-icon span { margin:0; line-height:40px; }

 /*left-icon*/
.counter.right-icon { position:relative; padding-right:70px; }
.counter.right-icon .icon { font-size:40px; line-height:50px; position:absolute; right:0; bottom:auto; top:0; }
.counter.right-icon span { margin:0; line-height:40px; text-align: right; }
.counter.right-icon label  { text-align: right; display: block; }